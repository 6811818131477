import cn from 'classnames';
import { FC, MouseEvent } from 'react';
import useLocalization from 'src/services/localization/useLocalization';
import { Product } from '../../../redux/apiTypes';
import isArrayValid from '../../../utils/js-helpers/isArrayValid';
import ProductPlaceholder from '../Placeholders/ProductPlaceholder/ProductPlaceholder';
import ProductTile from '../ProductTile/ProductTile';
import ProductTileMini from '../ProductTileMini/ProductTileMini';
import SeeMoreCard from '../SeeMoreCard/SeeMoreCard';
import SliderWrapper from '../SliderWrapper/SliderWrapper';
import { MainLink } from '../SliderWrapper/types';
import { styles } from './ProductsCarousel.styles';

/** @todo move to common place */
export interface ViewProduct extends Product {
  isFavorite: boolean;
  isAdded: boolean;
  amount: number;
}

export interface ProductsCarouselProps {
  items: ViewProduct[];
  title: string;
  buttonUrl?: string;
  slideCount?: number;
  simulateTouch?: boolean;
  isFetching?: boolean;
  isSliderFullWidth?: boolean;
  categoryProductsCount?: number;
  withShowAllButton?: boolean;
  productPlace?: string;
  dataSuffix?: string;
  withTwoRows?: boolean;
  withMarginTop?: boolean;
  language: string;
  itemsCanBeAdded: boolean;
  withoutMarginBottom?: boolean;
  onProductTileClick(
    event: MouseEvent<HTMLAnchorElement, globalThis.MouseEvent>,
    product: Product,
  ): void;
  onAddToCart(product: Product): void;
  onAmountChange(amount: number, product: Product): void;
  onFavoriteClick(product: Product): void;
}

const cardsCountInSlide = 6;

const ProductsCarousel: FC<ProductsCarouselProps> = props => {
  const localize = useLocalization();

  const {
    items,
    title,
    buttonUrl,
    isFetching,
    slideCount = 5,
    withShowAllButton,
    productPlace,
    dataSuffix,
    withTwoRows,
    withMarginTop,
    language,
    itemsCanBeAdded,
    withoutMarginBottom,
    onProductTileClick,
    onAddToCart,
    onAmountChange,
    onFavoriteClick,
  } = props;

  const initData = isFetching
    ? Array<ViewProduct>(slideCount).fill(null)
    : items;

  if (!isFetching && !isArrayValid(items)) {
    return null;
  }

  const mainLink: MainLink = {
    title: localize('general.show-all'),
    href: buttonUrl,
  };

  const navButtonsAreHidden = initData.length <= slideCount;
  const showAllBtnIsDisplayed = withShowAllButton;

  const carouselItemClass = cn(
    'ProductsCarousel__item', {
    'ProductsCarousel__item_wide': slideCount === 4,
    'ProductsCarousel__item_flexWrapper': withTwoRows,
    'ProductsCarousel__item_withoutBorder': withTwoRows,
  });

  const handleProductTileClick = (
    product: ViewProduct,
  ) => (event: MouseEvent<HTMLAnchorElement, globalThis.MouseEvent>) => {
    onProductTileClick(event, product);
  };

  const handleAddToCart = (product: Product) => () => {
    onAddToCart(product);
  };

  const getRowTiles = () => {
    const rowTiles = [];

    for (let rowIndex = 0; rowIndex < initData.length; rowIndex += cardsCountInSlide) {
      const tiles = [];

      for (let itemIndex = rowIndex; itemIndex < rowIndex + cardsCountInSlide; itemIndex++) {
        const item = items[itemIndex];

        tiles.push(item &&
          <ProductTileMini
            key={itemIndex}
            data={item}
            amount={item.amount}
            withBorderInSlider
            productPlace={productPlace}
            language={language}
            itemIsAdded={item.isAdded}
            itemCanBeAdded={itemsCanBeAdded}
            onClick={handleProductTileClick(item)}
            onAddToCart={handleAddToCart(item)}
            onAmountChange={newAmount => onAmountChange(newAmount, item)}
          />,
        );
      }

      rowTiles.push(tiles);
    }

    return rowTiles;
  };

  return (
    <div
      className={cn(
        'ProductsCarousel', {
        ProductsCarousel_withoutMarginBottom: withoutMarginBottom,
      })}
      data-marker={
        withTwoRows ? 'Double Products Carousel' : 'Products Carousel'
      }
      data-testid='products_carousel'
    >
      <SliderWrapper
        title={title}
        mainLink={mainLink}
        withNavButtons={!navButtonsAreHidden}
        withMarginTop={withMarginTop}
        slidesPerScroll={slideCount}
      >
        {
          withTwoRows && !isFetching && getRowTiles().map(
            (tile, index) => (
              <div
                key={index}
                className={carouselItemClass}
                data-testid={`carouselItem_${dataSuffix || 'default'}`}
              >
                {tile}
              </div>
            ),
          )
        }

        {!withTwoRows && initData.map((item, index) => (
          <div
            key={index}
            className={carouselItemClass}
            data-testid={`carouselItem_${dataSuffix || 'default'}`}
          >

            {
              isFetching ? (
                <ProductPlaceholder />
              ) : (
                <ProductTile
                  locale={language}
                  product={item}
                  isFavorite={item.isFavorite}
                  isInSlider
                  productPlace={productPlace}
                  itemIsAdded={item.isAdded}
                  amount={item.amount}
                  canBeAdded={itemsCanBeAdded}
                  onFavoriteClick={() => onFavoriteClick(item)}
                  onAddToCart={() => onAddToCart(item)}
                  onClick={handleProductTileClick(item)}
                  onChangeAmount={amount => onAmountChange(amount, item)}
                />
              )
            }
          </div>
        ))}
        {showAllBtnIsDisplayed &&
          <div className={carouselItemClass}>
            <SeeMoreCard {...mainLink} />
          </div>
        }
      </SliderWrapper>

      <style jsx>{styles}</style>
    </div>
  );
};

ProductsCarousel.defaultProps = {
  isFetching: false,
  withShowAllButton: false,
  withTwoRows: false,
};

export default ProductsCarousel;
