import Link from "next/link";
import { useDispatch } from 'react-redux';
import { selectCartItems } from 'src/redux/cart/general/cartSelectors';
import { useAppSelector } from 'src/redux/hooks';
import { openModal } from 'src/redux/modals';
import PageButton from './PageButton/PageButton';
import { styles } from "./PageButtons.styles";
import usePageButtons from "./usePageButtons";

const PageButtons = () => {
  const language = useAppSelector((s) => s.app.language);
  const cartType = useAppSelector((s) => s.app.cartType);
  const cartIsEmpty = useAppSelector(selectCartItems).length === 0;

  const dispatch = useDispatch();

  const buttons = usePageButtons({
    language,
    withUnreadLabel: cartType === 'server' && !cartIsEmpty,
    onRecommendationsClick: () => {
      dispatch(openModal('recommendationsModal'));
    },
  });

  return (
    <ul className='PageButtons'>
      {
        buttons.map((button) => {
          return (
            <li
              className='PageButtons__item'
              key={button.label}
              data-marker={button.dataMarker}
            >
              {
                button.linkProps
                  ? <Link
                      href={button.linkProps.href}
                      as={button.linkProps.as}
                      data-marker={button.linkProps.dataMarker}
                    >
                      <PageButton {...button} />
                    </Link>
                  : <PageButton {...button} isButton />
              }
            </li>
          );
        })
      }
      <style jsx>{styles}</style>
    </ul>
  );
};


export default PageButtons;
