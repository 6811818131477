import { useEffect, useState } from 'react';
import { sendOpenRecommendationsClick } from 'src/components/containers/EmptyCart/helpers/analytics';
import useLocalization from 'src/services/localization/useLocalization';
import { PageButtonProps } from './PageButton/PageButton';
import { getRecommendationsClicked, setRecommendationsClicked } from './helpers';

export interface PageButton extends PageButtonProps {
  linkProps?: {
    href: string;
    as: string;
    dataMarker?: string;
  };
  dataMarker?: string;
}

export interface UsePageButtonsParams {
  language: string;
  onRecommendationsClick(): void;
  withUnreadLabel?: boolean;
}

const usePageButtons = (params: UsePageButtonsParams) => {
  const { onRecommendationsClick, language, withUnreadLabel } = params;

  const localize = useLocalization();

  const [canShowUnread, setCanShowUnread] = useState(
    !getRecommendationsClicked(),
  );

  const openRecommendations = () => {
    onRecommendationsClick();
    sendOpenRecommendationsClick();
    setCanShowUnread(false);
    setRecommendationsClicked();
  };

  useEffect(() => {
    const intervalId = window.setInterval(() => {
      const emptyCartWasDisplayed = getRecommendationsClicked();

      if (!emptyCartWasDisplayed) {
        setCanShowUnread(true);
      } else if (canShowUnread) {
        setCanShowUnread(false);
      }
    }, 60_000);

    return () => {
      window.clearInterval(intervalId);
    };
  }, [canShowUnread]);

  const unread = withUnreadLabel && canShowUnread;

  const pageButtons: PageButton[] = [
    {
      linkProps: {
        href: '/[lang]/lists',
        as: `/${language}/lists`,
      },
      label: localize('lists.title-2'),
      iconName: 'heart-full',
      dataMarker: 'Lists',
    },
    {
      onClick: openRecommendations,
      dataMarker: 'Recommendations button',
      label: localize('my_recommendations'),
      iconName: 'magic',
      unread,
    },
  ];

  return pageButtons;
};

export default usePageButtons;
