import css from 'styled-jsx/css';

export const styles = css`
  .PageButtons {
    display: flex;
    justify-content: center;
    align-items: center;
    list-style: none;
    gap: 8px;
  }
  :global(.PageButtons__item a) {
    text-decoration: none;
  }
`;
