import { useDispatch, useSelector } from 'react-redux';
import useLocalization from 'src/services/localization/useLocalization';
import { selectCartItems } from '../../../../../redux/cart/general/cartSelectors';
import { openModal } from '../../../../../redux/modals';
import { styles } from './CartButton.styles';

const CartButton = () => {
  const dispatch = useDispatch();
  const cart = useSelector(selectCartItems);
  const localize = useLocalization();

  const handleOpenModal = async () => {
    dispatch(openModal('cartModal'));
  };

  return (
    <button
      className='CartButton'
      onClick={handleOpenModal}
      type='button'
      data-marker='Basket'
      data-testid='cart_button'
    >
      <span className='CartButton__wrapper'>
        <span className='CartButton__icon icon-basket'/>
        {cart.length > 0 && (
          <span data-marker='Cart badge' className='CartButton__badge'>{cart.length}</span>
        )}
      </span>

      <span className='CartButton__label'>
        <span>{localize('cart.title')}</span>
      </span>

      <style jsx>{styles}</style>
    </button>
  );
};

export default CartButton;
