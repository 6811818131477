import Responsive from 'src/components/views/Responsive/Responsive';
import UnreadMark from '../UnreadMark/UnreadMark';
import { styles } from './PageButton.styles';

export interface PageButtonProps {
  iconName: 'heart-full' | 'magic';
  label: string;
  onClick?(): void;
  unread?: boolean;
  isButton?: boolean;
}

export default function PageButton(props: PageButtonProps) {
  const { iconName, label, onClick, unread, isButton } = props;

  return (
    <span
      className='PageButton'
      onClick={onClick}
      role={isButton ? 'button' : undefined}
    >
      <span className={`PageButton__icon icon-${iconName}`}>
        {unread && (
          <span className='PageButton__unreadMark'>
            <UnreadMark />
          </span>
        )}
      </span>
      <Responsive desktop>
        <span className='PageButton__label'>{label}</span>
      </Responsive>

      <style jsx>{styles}</style>
    </span>
  );
}
